import React from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { View, Text, Keyboard, TouchableOpacity, Image as Image_, Dimensions, ActivityIndicator, TextInput as TextInputNative } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import mixpanel from 'mixpanel-browser'
import { ScrollView } from 'react-native-web'
export { useActionSheet } from '@expo/react-native-action-sheet'

// Uncompatible/Unnecessary Web Packages
export const { RNReactNativeHapticFeedback, Clipboard, FFmpegKit, FFmpegKitConfig, ReturnCode, Share, RNFS, ReactNativeInAppReview } = {}

// Actionsheet
// const { showActionSheetWithOptions } = useActionSheet();

// Production
export const production = process.env.NODE_ENV === 'production'

// Page
export function Page(props) {
  return (
    <ScrollView id='app' contentContainerStyle={[props.style]}>
      {props.children}
      {/* <View style={{ maxWidth: 600, width: '100%', maxHeight: 900, height: '100%', position: 'static' }}>{props.children}</View> */}
    </ScrollView>
  )
}

export const screenWidth = Dimensions.get('window').width
export const screenHeight = Dimensions.get('window').height

// Open Link
export function openLink(url) {
  window.open(url, '_blank')
}

export function Rowify(props) {
  return (
    <View dataSet={{ rowify: 1 }} {...props} style={[s.row, props.style]}>
      {/* {props.children} */}
    </View>
  )
}

// Space
export function Space2(props) {
  const size = 2
  return <View style={{ width: size, height: size }} />
}
export function Space4(props) {
  const size = 4
  return <View style={{ width: size, height: size }} />
}
export function Space6(props) {
  const size = 6
  return <View style={{ width: size, height: size }} />
}
export function Space8(props) {
  const size = 8
  return <View style={{ width: size, height: size }} />
}
export function Space10(props) {
  const size = 10
  return <View style={{ width: size, height: size }} />
}
export function Space12(props) {
  const size = 12
  return <View style={{ width: size, height: size }} />
}
export function Space16(props) {
  const size = 16
  return <View style={{ width: size, height: size }} />
}
export function Space20(props) {
  const size = 20
  return <View style={{ width: size, height: size }} />
}
export function Space24(props) {
  const size = 24
  return <View style={{ width: size, height: size }} />
}
export function Space32(props) {
  const size = 32
  return <View style={{ width: size, height: size }} />
}
export function Space40(props) {
  const size = 40
  return <View style={{ width: size, height: size }} />
}
export function SpaceGrow(props) {
  return <View style={{ flex: 1 }} dataSet={{ grow: 1 }} />
}

// Titles
export function Title32(props) {
  return (
    <Text
      {...props}
      style={[
        {
          fontFamily: 'GTWalsheimPro-Bold',
          fontSize: 32,
          lineHeight: 36,
          color: 'black'
        },
        props.style
      ]}
    />
  )
}
export function Title24(props) {
  return (
    <Text
      {...props}
      style={[
        {
          fontFamily: 'GTWalsheimPro-Bold',
          fontSize: 24,
          lineHeight: 34,
          color: 'black'
        },
        props.style
      ]}
    />
  )
}
export function Title21(props) {
  return (
    <Text
      {...props}
      style={[
        {
          fontFamily: 'GTWalsheimPro-Medium',
          fontSize: 21,
          lineHeight: 28,
          color: 'black'
        },
        props.style
      ]}
    />
  )
}
export function Title19(props) {
  return (
    <Text
      {...props}
      style={[
        {
          fontFamily: 'GTWalsheimPro-Medium',
          fontSize: 19,
          lineHeight: 24,
          color: 'black'
        },
        props.style
      ]}
    />
  )
}
export function Title18(props) {
  return (
    <Text
      {...props}
      style={[
        {
          fontFamily: 'GTWalsheimPro-Medium',
          fontSize: 18,
          lineHeight: 23,
          color: 'black'
        },
        props.style
      ]}
    />
  )
}
export function Title16(props) {
  return (
    <Text
      {...props}
      style={[
        {
          fontFamily: 'GTWalsheimPro-Regular',
          fontSize: 16,
          lineHeight: 20,
          color: 'black'
        },
        props.style
      ]}
    />
  )
}
export function Title14(props) {
  return (
    <Text
      {...props}
      style={[
        {
          fontFamily: 'GTWalsheimPro-Regular',
          fontSize: 14,
          lineHeight: 18,
          color: 'black'
        },
        props.style
      ]}
    />
  )
}
export function Title12(props) {
  return (
    <Text
      {...props}
      style={[
        {
          fontFamily: 'Graphik-Regular',
          fontSize: 12,
          lineHeight: 14,
          color: 'black'
        },
        props.style
      ]}
    />
  )
}
export function Title10(props) {
  return (
    <Text
      {...props}
      style={[
        {
          fontFamily: 'GTWalsheimPro-Bold',
          fontSize: 10,
          lineHeight: 12,
          color: 'black'
        },
        props.style
      ]}
    />
  )
}

// Animations
export function AnimationScaleUp(props) {
  let { animate = true, style } = props
  return <View {...props} style={[animate && { animation: 'scaleUp 0.4s linear' }, style]} />
}

export function AnimationFadeInOut(props) {
  let { animate = true, style } = props
  return <View {...props} style={[animate && { animation: 'fadeInOut 0.4s' }, style]} />
}

export function AnimationFadeIn(props) {
  let { animate = true, style } = props
  return <View {...props} style={[animate && { animation: 'fadeIn 1s', animationFillMode: 'both' }, style]} />
}

export function AnimationRotate(props) {
  let { animate = true, style } = props
  return <View {...props} style={[animate && { animation: 'rotate 3.6s infinite linear' }, style]} />
}

export function AnimationPulse(props) {
  let { animate = true, style } = props
  return <View {...props} style={[animate && { animation: 'pulse 2.2s infinite linear' }, style]} />
}

export function AnimationGems(props) {
  return <View {...props} />
}

export function AnimationShake(props) {
  let { animate = true, style } = props
  return <View {...props} style={[animate && { animation: 'shake 2.2s infinite linear' }, style]} />
}

const shadow = (y = 0, radius = 8, color = 'black', opacity = 1) => ({
  backgroundColor: 'black',
  shadowOffset: { width: 0, height: y },
  shadowRadius: radius,
  shadowColor: color,
  shadowOpacity: opacity
})

const textShadow = (y = 0, radius = 8, color = 'black') => ({
  textShadowColor: color,
  textShadowOffset: { width: 0, height: y },
  textShadowRadius: radius
})

export const s = {
  // theme2: '#DC00F5',
  theme1: 'hsla(216, 100%, 64%, 1)',
  theme2: 'hsla(216, 100%, 64%, 1)',
  theme3: 'hsla(216, 100%, 44%, 1)',
  // theme1: '#000',
  // theme2: '#313',
  auto: { width: 'auto' },
  gutter8: { paddingHorizontal: 8 },
  gutter16: { paddingHorizontal: 16 },
  gutter20: { paddingHorizontal: 20 },
  gutter24: { paddingHorizontal: 24 },
  gutter32: { paddingHorizontal: 32 },
  gutter40: { paddingHorizontal: 40 },
  width: { width: '100%' },
  icon: { width: 24, height: 24 },
  iconLarge: { width: 36, height: 36 },
  row: { flexDirection: 'row' },
  wrap: { flexDirection: 'row', flexWrap: 'wrap' },
  center: { justifyContent: 'center', alignItems: 'center' },
  spread: { justifyContent: 'space-between' },
  absolute: { position: 'absolute', width: '100%', zIndex: 1 },
  flex: { flex: 1 },
  red: { backgroundColor: 'red' },
  blue: { backgroundColor: 'blue' },
  blackText: { color: 'black' },
  textCenter: { textAlign: 'center' },
  shadow: shadow,
  textShadow: textShadow,
  shadow1: shadow(2, 1, 'rgba(0,0,0,0.6)'),
  shadow2: shadow(16, 32, 'rgba(0,0,0,0.2)'),
  textShadow1: textShadow(2, 1, 'rgba(0,0,0,0.4)')
}

export const Image = props => {
  let { url, resizeMode = 'contain' } = props
  let source = url
  if (typeof url === 'string') source = { uri: url }

  return <Image_ resizeMode={resizeMode} {...props} source={source} />
}
export const preload = async urls => {
  if (!Array.isArray(urls)) urls = [urls]
  return await Promise.all(urls.map(d => Image_.prefetch(d)))
}
export async function imageToBlob(url) {
  const response = await fetch(url)
  return await response.blob()
}
export async function request(method, url, data = '', headers = {}) {
  try {
    const controller = new AbortController()
    setTimeout(() => controller.abort(), 3000)
    let r = await fetch(url, {
      method: method.toUpperCase(),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers
      },
      body: typeof data === 'object' && data.constructor.name !== 'Blob' ? JSON.stringify(data) : data != null ? data : undefined,
      signal: controller.signal
    })

    r = await r.text()
    try {
      return JSON.parse(r)
    } catch (e) {
      return r
    }
  } catch (e) {
    console.log('Error with', data, e)
    return { error: 'An error occured' }
  }
}

export function LinearGradient(props) {
  let { colors, style } = props
  return (
    <View
      {...props}
      style={[
        {
          background: `linear-gradient(180deg, ${colors[0]} 0%, ${colors[1]} 100%)`
        },
        style
      ]}
    />
  )
}

// Array
export const createArray = num => {
  return [...Array(num).keys()]
}

// Blah
export function vibrate(level = 1) {
  let p = 'impactLight'
  if (level === 2) p = 'impactHeavy'
  RNReactNativeHapticFeedback?.trigger(p, { enableVibrateFallback: true })
}

// Init
export const useInit = (f, changeOn = []) => {
  useEffect(() => {
    f()
  }, changeOn)
}

// Sleep
export function sleep(mili = 0) {
  return new Promise(done => {
    setTimeout(done, mili)
  })
}

// Store
const StoreContext = createContext()
export const store = {}
export const useStore = () => useContext(StoreContext)
export function StoreProvider(props) {
  const [state, setState] = useState(store)
  const setStore = data => {
    Object.assign(store, data)
    setState({ ...store })
  }

  // Keyboard up and down
  useEffect(() => {
    let s1 = Keyboard.addListener('keyboardWillShow', () => {
      setStore({ keyboardUp: Math.random() })
    })
    let s2 = Keyboard.addListener('keyboardWillHide', () => setStore({ keyboardUp: 0 }))
    return () => {
      s1.remove()
      s2.remove()
    }
  })

  return <StoreContext.Provider value={[state, setStore]}>{props.children}</StoreContext.Provider>
}

// Storage
export const getData = async key => {
  try {
    return JSON.parse(await AsyncStorage.getItem(key))
  } catch (e) {
    return null
  }
}
export const storeData = async data => {
  try {
    for (let k in data) {
      await AsyncStorage.setItem(k, JSON.stringify(data[k]))
    }
  } catch (e) {
    return null
  }
}
export const clearData = async () => {
  try {
    return await AsyncStorage.clear()
  } catch (e) {
    return null
  }
}

//
export const navigation = {}

// Button
export function Button(props) {
  let { loading, style } = props
  let _vibrate = props.vibrate === undefined ? 'normal' : props.vibrate

  let [lloading, setLoading] = useState(false)

  return (
    <TouchableOpacity
      {...props}
      style={[{ ...s.auto, pointer: 'cursor' }, style]}
      hitSlop={8}
      onPress={async () => {
        if (_vibrate === 'normal') {
          vibrate()
        } else if (_vibrate === 'hard') {
          vibrate(2)
          setTimeout(() => vibrate(2), 75)
        }
        if (loading) setLoading(true)
        await props.onPress?.()
        if (loading) setLoading(false)
      }}>
      {props.children}
      {lloading && (
        <ActivityIndicator
          color={'#666'}
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            left: 10,
            right: 10,
            top: 10,
            bottom: 10,
            borderRadius: 100
          }}
        />
      )}
    </TouchableOpacity>
  )
}

export function Link(props) {
  return (
    <Button {...props}>
      <Text style={{ textDecorationLine: 'underline' }}>{props.children}</Text>
    </Button>
  )
}

export const getFlagEmoji = countryCode => {
  if (!countryCode) return ''
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt())
  return String.fromCodePoint(...codePoints)
}

export function useSafeAreaInsets() {
  return {
    bottom: 0,
    top: 0
  }
}

export function Dashes(props) {
  let { total, index } = props

  let width = `${100 / total - 2}%`
  return (
    <View style={{ ...s.row, ...s.spread }}>
      {[...Array(total).keys()].map(d => (
        <View
          style={[
            {
              width: width,
              height: 4,
              borderRadius: 100,
              backgroundColor: index === d ? 'white' : 'rgba(255,255,255,0.3)'
            }
          ]}
          key={d}
        />
      ))}
    </View>
  )
}

export const getDeviceTimezone = () => {
  return parseInt(-new Date().getTimezoneOffset() / 60)
}

export function userRedacted() {
  let { _object, id, name, region, pfp, country, age } = store.user
  return { _object, id, name, region, pfp, country, age }
}

// ActionSheet
export function Options({ _ref, children, destructive }) {
  // return (
  //   <ActionSheet
  //     ref={_ref}
  //     useCustomActionSheet={true}
  //     options={['Cancel', ...children.map(d => d[0])]}
  //     cancelButtonIndex={0}
  //     destructiveButtonIndex={destructive && children.length}
  //     tintColor={'#3347FF'}
  //     onPress={async index => {
  //       if (index === 0) return
  //       children[index - 1][1]()
  //     }}
  //   />
  // )
}

export function Button1(props) {
  let { icon, text, disabled = false, animate = false, tintColor = s.theme3, Animation = AnimationShake } = props

  return (
    <Button {...props}>
      <Animation animate={animate} style={{ alignItems: 'baseline' }}>
        <View
          dataSet={{ button: 1 }}
          style={[
            {
              ...s.row,
              ...s.center,
              paddingHorizontal: 20,
              height: 50,
              borderWidth: 1,
              borderColor: 'black'
            },
            props.buttonStyle
          ]}>
          {icon && (
            <>
              <Image url={icon} style={{ ...s.iconLarge }} tintColor={tintColor} />
              <Space2 />
            </>
          )}
          <Title18 style={[{ color: 'black', marginTop: 1 }, props.textStyle]}>{text}</Title18>
        </View>
      </Animation>
    </Button>
  )
}

export const TextInput = props => {
  return <TextInputNative placeholderTextColor='rgba(0,0,0,0.4)' keyboardAppearance={'dark'} {...props} style={[{ width: '100%', paddingVertical: 12, paddingHorizontal: 12, fontFamily: 'GTWalsheimPro-Medium', fontSize: 18, color: '#334', backgroundColor: '#FFF7F5', borderWidth: 1, borderColor: 'rgba(0,0,0,0.4)' }, props.style]} />
}

export function metadata(name, title, description, url) {
  let language = 'en'

  document.documentElement.lang = language
  document.querySelector('meta[property="og:site_name"]').content = name

  // document.querySelector('link[rel="canonical"]').href = host + window.location.pathname
  // document.querySelector('meta[property="og:url"]').content = host + window.location.pathname

  document.querySelector('title').textContent = title
  document.querySelector('meta[property="og:title"]').content = title
  document.querySelector('meta[name="twitter:title"]').content = title

  document.querySelector('meta[name="description"]').content = description
  document.querySelector('meta[property="og:description"]').content = description
  document.querySelector('meta[name="twitter:description"]').content = description

  document.querySelector('meta[property="og:image"]').content = `${url}/static/images/logo.jpg?v=2`
  document.querySelector('meta[name="twitter:image"]').content = `${url}/static/images/logo.jpg?v=2`
}

// MIXPANEL ANALYTICS
export function initAnalytics() {
  mixpanel.init('e815c346d86f9ecba1aaa9427e97f99f', {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage'
  })
}

window.identify = (...args) => {
  print('ANALYTICS_IDENTIFY', args[0])
  return mixpanel.identify(...args)
}
window.track = (...args) => {
  print('ANALYTICS', args[0], args[1])
  return mixpanel.track(...args)
}
