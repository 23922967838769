import React from 'react'
import { Alert, View, Text } from 'react-native'
import { Button, Button1, Title18Bold, LinearGradient, Image, Page, useInit, Space12, Space16, Space24, Space32, Space40, Space8, SpaceGrow, Title20Bold, TitleSmall, getData, init, navigation, openLink, s, Title28Bold, Title24Bold, Title12, Title20SemiBold, storeData, navigationReset, Title16Regular, Space4, Title24, Title19, Title21, Title32, Title16, TextInput, Title14, Title18, Space20, Link, Rowify, Space6 } from './Util.web'
import { useNavigate } from 'react-router-dom'
import { Footer } from './Home'

export function Book(props) {
  const navigate = useNavigate()

  return (
    <Page style={{ alignItems: 'center' }}>
      {/* Header */}
      <View style={{ ...s.width, ...s.center, backgroundColor: '#222', paddingVertical: 80, paddingHorizontal: 40 }}>
        <Image url={require('./assets/renee2.jpg')} resizeMode={'cover'} style={{ ...s.absolute, height: '100%', zIndex: 0, opacity: 0.36 }} />
        <Button
          onPress={() => {
            navigate('/')
          }}>
          <Image url={require('./assets/logo.png')} resizeMode={'contain'} style={{ width: 240, height: 92 }} tintColor={'white'} />
        </Button>

        {/* <Title32 style={{ color: 'white', ...s.textCenter }}>SCHEDULE CONSULTATION</Title32> */}
        {/* <Space24 />
        <Title21 style={{ color: 'white', maxWidth: 800, ...s.textCenter }}>As a leading expert in weight-loss, Renee Fitton, RD, provides completely customized plans that deliver the results you want. </Title21>
        <Space32 />
        <Button1 text={'See Background'} buttonStyle={{ borderColor: 'white' }} textStyle={{ color: 'white' }} /> */}
      </View>

      {/* Contact */}
      <View style={{ ...s.width, justifyContent: 'center', maxWidth: 700, paddingVertical: 60, paddingHorizontal: 40 }}>
        <Title32 style={{ ...s.textCenter }}>BOOK A VIRTUAL 30 MIN CONSULTATION</Title32>
        <Space32 />
        <Title18>Name:</Title18>
        <Space4 />
        <TextInput />
        <Space32 />
        <Title18>Email:</Title18>
        <Space4 />
        <TextInput />
        <Space32 />
        <Title18>Phone:</Title18>
        <Space4 />
        <TextInput />
        <Space32 />
        <Title18>Please describe your nutrition goals:</Title18>
        <Space4 />
        <TextInput multiline={true} style={{ height: 200 }} />
        <Space20 />
        <Button1
          text={'Submit'}
          onPress={() => {
            alert("Message sent! We'll get back to you within 48 hours.")
          }}
        />
      </View>

      {/* Footer */}
      <Footer />
    </Page>
  )
}
