import React from 'react'
import { Alert, View, Text } from 'react-native'
import { Button, Button1, Title18Bold, LinearGradient, Image, Page, useInit, Space12, Space16, Space24, Space32, Space40, Space8, SpaceGrow, Title20Bold, TitleSmall, getData, init, navigation, openLink, s, Title28Bold, Title24Bold, Title12, Title20SemiBold, storeData, navigationReset, Title16Regular, Space4, Title24, Title19, Title21, Title32, Title16, TextInput, Title14, Title18, Space20, Link, Rowify, Space6, AnimationFadeIn } from './Util.web'
import { useNavigate } from 'react-router-dom'

export function Home(props) {
  const navigate = useNavigate()

  return (
    <Page style={{ height: '100%', ...s.center }}>
      <Title32>Coming Soon</Title32>
    </Page>
  )
  return (
    <Page style={{ alignItems: 'center' }}>
      {/* Announcement */}
      <Rowify style={{ ...s.width, ...s.center, backgroundColor: '#d87', paddingVertical: 16, paddingHorizontal: 24 }}>
        <Title14 style={{ color: 'white', ...s.textCenter, fontFamily: 'GTWalsheimPro-Medium', maxWidth: 600 }}>We have expanded! Along with Los Angeles, Renée virtually counsels clients worldwide.</Title14>
        <Space12 />
        <Button1
          text={'Book Virtual Appointment'}
          buttonStyle={{ borderColor: 'white' }}
          textStyle={{ color: 'white' }}
          onPress={() => {
            navigate('/book')
          }}
        />
      </Rowify>

      {/* Navbar */}
      <Rowify style={{ ...s.width, ...s.center, maxWidth: 900, height: 220 }}>
        <Image url={require('./assets/logo.png')} resizeMode={'contain'} style={{ width: 260, height: 100 }} />
        <SpaceGrow />

        <View style={{ ...s.row, ...s.center }}>
          {/* <Button>
            <Title18>CALL NOW</Title18>
          </Button>
          <Space40 />
          <Button>
            <Title18>SERVICES</Title18>
          </Button>
          <Space40 /> */}
          <Title19>
            <Link
              onPress={() => {
                navigate('/book')
              }}>
              REQUEST YOUR FREE CONSULTATION
            </Link>
          </Title19>
        </View>
      </Rowify>

      {/* Top Rated */}
      <AnimationFadeIn style={{ ...s.width, ...s.center, backgroundColor: '#222', paddingVertical: 80, paddingHorizontal: 40 }}>
        <Image url={require('./assets/renee2.jpg')} resizeMode={'cover'} style={{ ...s.absolute, height: '100%', zIndex: 0, opacity: 0.4 }} />
        <Title32 style={{ color: 'white', ...s.textCenter }}>LOS ANGELES DIETITIAN</Title32>
        <Space24 />
        <Title21 style={{ color: 'white', maxWidth: 800, ...s.textCenter }}>As a leading expert in weight-loss, Renee Fitton, RD, provides completely customized plans that deliver the results you want. </Title21>
        <Space32 />
        <Button1
          text={'See Background'}
          buttonStyle={{ borderColor: 'white' }}
          textStyle={{ color: 'white' }}
          onPress={() => {
            window.open('https://www.linkedin.com/in/reneefitton/', '_blank').focus()
          }}
        />
      </AnimationFadeIn>

      {/* Meet Renee */}
      <AnimationFadeIn style={{ animationDelay: '0.3s' }}>
        <Rowify style={{ maxWidth: 900, ...s.center, paddingVertical: 60, paddingHorizontal: 56 }}>
          <View style={{ maxWidth: 400, ...s.center }}>
            <View style={{ ...s.row, ...s.center, backgroundColor: 'black', paddingHorizontal: 24, paddingVertical: 16 }}>
              <Title21 style={{ color: 'white' }}>Meet</Title21>
              <Space6 />
              <Image url={require('./assets/logo-short.png')} resizeMode={'contain'} style={{ width: 80, height: 36, top: -3 }} tintColor={'white'} />
            </View>
            <Space24 />
            <Title16 style={{ lineHeight: 28 }}>Renee specializes in creating customized weight-loss plans that work. As an Integrative Registered Dietitian-Nutritionist, she has trained extensively in both clinical and integrative nutrition. Along with being a certified health coach and personal trainer, she provides the perfect trifecta of support you will need to achieve your goals. With a truly holistic approach, she considers factors such as specialized diets, nutrient enhancements, cooking skills, physical activity, work demands, personal environment, stress management, sleep, and energy optimization. Renée understands that one-size does not fit all, and creates completely customized plans that get results.</Title16>
            <Space24 />
            <Button1
              text={'More About Renee'}
              onPress={() => {
                window.open('https://www.linkedin.com/in/reneefitton/', '_blank').focus()
              }}
            />
          </View>
          <Space24 />
          <Image url={require('./assets/renee.jpg')} resizeMode={'cover'} style={{ overflow: 'hidden', width: '100%', height: 420, borderColor: 'black', borderWidth: 4 }} />
        </Rowify>
      </AnimationFadeIn>

      <View style={{ ...s.width, ...s.center, paddingVertical: 80, paddingHorizontal: 40 }}>
        <Image url={require('./assets/floral.jpg')} resizeMode={'cover'} style={{ ...s.absolute, height: '100%', zIndex: 0, opacity: 0.4 }} />
        <Title32>EMBODY YOUR BEST SELF</Title32>
        <Space40 />
        <Rowify style={{ maxWidth: 800, ...s.center }}>
          {/* <View style={{ ...s.row, ...s.center,  }}> */}
          {/* <Space40 /> */}
          <Title18 style={{ lineHeight: 36, ...s.width }}>"Make plant-based lifestyle changes that are simple, sustainable, and easy to corperate - based on your unique health profile and personal dietary preferences"</Title18>
          <Space40 />
          {/* <SpaceGrow /> */}
          <Title16 style={{ lineHeight: 28, ...s.width }}>Renee offers an integrative approach to weight loss that puts you at the center - and gets you results that last. She puts the power back in your hands with completely customized programs that work. Every time. ​ With Renee Fitton, empowerment and accountability are key. She believes that because you are unique, your program should be too. There are no restrictive meal plans. There is no food guilt. Instead, every client is taught to implement her or his own customized plan anywhere and everywhere. That way life can be vibrant, fun, and</Title16>
          {/* </View> */}
        </Rowify>
      </View>

      {/* Services */}
      <View style={{ ...s.width, backgroundColor: '#222', ...s.center, paddingVertical: 80 }}>
        <Image url={require('./assets/vegetables.jpg')} resizeMode={'cover'} style={{ ...s.absolute, height: '100%', zIndex: 0, opacity: 0.4 }} />
        <Title32 style={{ color: 'white' }}>WHAT’S OFFERED</Title32>
        <Space40 />
        <Rowify style={{ ...s.width, maxWidth: 1000, ...s.center, ...s.spread }}>
          {['Completely Customized Diet Management', 'Unlimited Accountability + Email Support', 'Comprehensive Plans for Both Nutrition + Fitness'].map(d => (
            <View style={{ backgroundColor: 'black', borderRadius: 12, width: 285, ...s.center, height: 316, marginBottom: 20 }} key={d}>
              <Image style={{ width: 32, height: 32 }} url={require('./assets/check.png')} />
              <Space32 />
              <Title18 style={{ color: 'white', ...s.textCenter, width: '80%' }}>{d}</Title18>
              <Space32 />
              <Button1
                text={'Learn More'}
                buttonStyle={{ borderColor: 'white' }}
                textStyle={{ color: 'white' }}
                onPress={() => {
                  navigate('/book')
                }}
              />
            </View>
          ))}
        </Rowify>
      </View>

      {/* Contact */}
      <View style={{ ...s.width, justifyContent: 'center', maxWidth: 700, paddingVertical: 60, paddingHorizontal: 40 }}>
        <Title32 style={{ ...s.textCenter }}>BOOK A VIRTUAL 30 MIN CONSULTATION</Title32>
        <Space32 />
        <Title18>Name:</Title18>
        <Space4 />
        <TextInput />
        <Space32 />
        <Title18>Email:</Title18>
        <Space4 />
        <TextInput />
        <Space32 />
        <Title18>Phone:</Title18>
        <Space4 />
        <TextInput />
        <Space32 />
        <Title18>Please describe your nutrition goals:</Title18>
        <Space4 />
        <TextInput multiline={true} style={{ height: 200 }} />
        <Space20 />
        <Button1
          text={'Submit'}
          onPress={() => {
            alert("Message sent! We'll get back to you within 48 hours.")
          }}
        />
      </View>

      {/* Footer */}
      <Footer />
    </Page>
  )
}

export function Footer() {
  const navigate = useNavigate()

  return (
    <View style={{ ...s.width, ...s.center, backgroundColor: 'black', paddingVertical: 80 }}>
      <Rowify style={{ maxWidth: 700, ...s.width, ...s.center }}>
        <Image url={require('./assets/logo.png')} resizeMode={'contain'} style={{ width: 240, height: 92 }} tintColor={'white'} />
        <SpaceGrow />

        <View style={{ ...s.center }}>
          <Title21 style={{ color: 'white' }}>Contact</Title21>
          <Title18 style={{ color: 'white', fontFamily: 'GTWalsheimPro-Regular' }}>
            Tel:{' '}
            <Link
              onPress={() => {
                window.open('tel:+13104303824')
              }}>
              (310) 430-3824
            </Link>
          </Title18>
          <Space4 />
          <Title18 style={{ color: 'white', fontFamily: 'GTWalsheimPro-Regular' }}>
            Email:{' '}
            <Link
              onPress={() => {
                window.open('mailto:renee@fittonnutrition.com')
              }}>
              renee@fittonnutrition.com
            </Link>
          </Title18>
          <Space40 />

          <Title18 style={{ color: 'white', fontFamily: 'GTWalsheimPro-Regular' }}>© 2024 Renee Fitton LLC</Title18>
          <Space8 />
          <Rowify>
            <Title18 style={{ color: 'white', fontFamily: 'GTWalsheimPro-Regular' }}>
              <Link
                onPress={() => {
                  navigate('/privacy')
                }}>
                Privacy Policy
              </Link>
            </Title18>
            <Space12 />
            <Title18 style={{ color: 'white', fontFamily: 'GTWalsheimPro-Regular' }}>
              <Link
                onPress={() => {
                  navigate('/terms')
                }}>
                Terms of Service
              </Link>
            </Title18>
          </Rowify>
        </View>
      </Rowify>
    </View>
  )
}
