import React from 'react'
import { Alert, View, Text } from 'react-native'
import { Button, Button1, Title18Bold, LinearGradient, Image, Page, useInit, Space12, Space16, Space24, Space32, Space40, Space8, SpaceGrow, Title20Bold, TitleSmall, getData, init, navigation, openLink, s, Title28Bold, Title24Bold, Title12, Title20SemiBold, storeData, navigationReset, Title16Regular, Space4, Title24, Title19, Title21, Title32, Title16, TextInput, Title14, Title18, Space20, Link, Rowify, Space6 } from './Util.web'
import { useNavigate } from 'react-router-dom'
import { Footer } from './Home'

export function Privacy(props) {
  const navigate = useNavigate()

  return (
    <Page style={{ alignItems: 'center' }}>
      {/* Header */}
      <View style={{ ...s.width, ...s.center, backgroundColor: '#222', paddingVertical: 80, paddingHorizontal: 40 }}>
        <Image url={require('./assets/renee2.jpg')} resizeMode={'cover'} style={{ ...s.absolute, height: '100%', zIndex: 0, opacity: 0.36 }} />
        <Button
          onPress={() => {
            navigate('/')
          }}>
          <Image url={require('./assets/logo.png')} resizeMode={'contain'} style={{ width: 240, height: 92 }} tintColor={'white'} />
        </Button>
      </View>

      {/* Contact */}
      <View style={{ ...s.width, maxWidth: 700, paddingVertical: 60, paddingHorizontal: 40, minHeight: 600 }}>
        <Title32 style={{ ...s.textCenter }}>PRIVACY POLICY</Title32>
        <Space32 />
        <Title18>...</Title18>
      </View>

			{/* Footer */}
      <Footer />
    </Page>
  )
}
