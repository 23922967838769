import { production, getData, openLink, imageToBlob, request, sleep, store, storeData, userRedacted, Clipboard, FFmpegKit, FFmpegKitConfig, ReturnCode, Share, RNFS, ReactNativeInAppReview } from './Util.web'

// Baseurl
export let baseUrl = 'http://mm.local:3000'
export let prodUrl = 'https://www.snapyours.app'
export let termsUrl = 'https://glacier-stool-8a0.notion.site/Terms-Conditions-bb8e40225bbe4a29a338fb3b9061301d'
export let privacyUrl = 'https://glacier-stool-8a0.notion.site/Privacy-Policy-300660f9ebb54a578ed4f10919b2fb9b'
export let appleStore = 'https://apps.apple.com/us/app/snap-yours/id6478239938'
export let playStore = null
if (production) baseUrl = prodUrl

export function download() {
  if (navigator.userAgent.toLowerCase().indexOf('android') >= 0) {
    track('download', { device: 'android' }, () => {
      alert('Hang tight! We are expecting to be the Android Play store by end of September!')
    })
  } else {
    track('download', { device: 'ios' }, () => {
      openLink(appleStore)
    })
  }
}
